import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import typographyStyles from "../styles/typography.module.scss"
import elementStyles from "../styles/elements.module.scss"
import buttonStyles from "../styles/buttons.module.scss"
import headerStyles from "../styles/header.module.scss"
import footerStyles from "../styles/footer.module.scss"

class Layout extends React.Component {
  render() {
    const { location, title, children, hideHeader, hideFooter } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    let footer

    if(!this.props.hideHeader) {
      header = (
        <header className={headerStyles.siteHeader}>
          <div
            className={elementStyles.container}
            style={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`
            }}>
            <div style={{
                flexBasis: `auto`
              }}>
              <Link
                className={buttonStyles.buttonBack}
                to={`/`}
              >
                Back Home
              </Link>
            </div>
            <StaticQuery
              query={graphql`
                {
                  wordpressAcfOptions {
                    options {
                      dribbble
                      github
                      facebook
                      instagram
                      twitter
                    }
                  }
                }
              `}
              render={data => (
                <ul className={headerStyles.socialNav}>
                    <li>
                      <a href={data.wordpressAcfOptions.options.dribbble} target="_blank"><i class="fab fa-dribbble"></i></a>
                    </li>
                    <li>
                      <a href={data.wordpressAcfOptions.options.twitter} target="_blank"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href={data.wordpressAcfOptions.options.instagram} target="_blank"><i class="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a href={data.wordpressAcfOptions.options.github} target="_blank"><i class="fab fa-github"></i></a>
                    </li>
                </ul>
              )}
            ></StaticQuery>
          </div>
        </header>
      )
    }

    if(!this.props.hideFooter) {
      footer = (
        <footer className={footerStyles.siteFooter}>
          <div className={elementStyles.container}>
            <span className="copyright">© {new Date().getFullYear()}</span>
          </div>
        </footer>
      )
    }

    return (
      <div className="site">
        <Helmet>
          <script src="https://kit.fontawesome.com/27b3dd1eef.js" crossorigin="anonymous"></script>
        </Helmet>
        {header}
        <main>{children}</main>
        {footer}
      </div>
    )
  }
}

export default Layout
